import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import CustomerList from './customer_list.vue';
import CompanyList from './company_list.vue';
import ProductSeries from './product_series.vue';

formCreate.component('ProductSeries', ProductSeries);
formCreate.component('CompanyList', CompanyList);
formCreate.component('CustomerList', CustomerList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'new_product_apply_form',
      formParentCode: 'CRM20210913000002691',
      buttonText: {
        submit: '保存',
      },
      buttons: {
        submitAudit: this.formConfig.code !== 'view',
      },
    };
  },

  methods: {
    // 重置规则
    setRule(v) {
      const item = v;
      if (item.field === 'productSeriesName') { // 产品系列
        item.props = {
          ...item.props,
          params: {
            functionCode: 'product_series',
          },
          getValue: (field) => this.getFieldValue(field),
          title: item.title,
        };
      }
      if (item.field === 'customerName') { // 选择客户
        item.props = {
          ...item.props,
          params: {
            functionCode: 'customer_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }

      if (item.field === 'companyName') { // 选择客户
        item.props = {
          ...item.props,
          params: {
            functionCode: 'company_list',
          },
          title: v.title,
          getValue: (field) => this.getFieldValue(field),
        };
      }
      if (item.field === 'alcoholDegree') { // 酒精度
        item.on = {
          change: (e) => {
            this.setValue({
              alcoholDegree: e,
            });
          },
          getLabel: (e) => {
            this.setValue({
              alcoholDegreeName: e,
            });
          },
        };
      }

      return v;
    },

    formComplete() {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      const { type, id } = this.formConfig;

      const companyName = this.getRule('companyName');
      companyName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            companyName: e[0].companyName,
            companyCode: e[0].companyCode,
          });
        } else {
          this.setValue({
            companyName: null,
            companyCode: null,
          });
        }
      };
      // 产品系列
      const productSeriesName = this.getRule('productSeriesName');
      productSeriesName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            productSeriesName: e[0].productLevelName,
            productSeries: e[0].productLevelCode,
          });
        } else {
          this.setValue({
            productSeriesName: null,
            productSeries: null,
          });
        }
      };
      // 客户名称
      const customerName = this.getRule('customerName');
      customerName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            customerName: e[0].customerName,
            customerCode: e[0].customerCode,
            legalRepresentative: e[0].legalRepresentative,
            customerContact: e[0].mdmCustomerContactVos ? e[0].mdmCustomerContactVos[0].contactPhone : '',
            responsibleCode: userInfo.userName,
            responsibleName: userInfo.fullName,
            registeredAddress: e[0].registeredAddress,
            responsibleOrgCode: userInfo.orgCode,
            responsibleOrgName: userInfo.orgName,
          });
        } else {
          this.setValue({
            customerName: null,
            customerCode: null,
            legalRepresentative: null,
            customerContact: null,
            registeredAddress: null,
            responsibleName: null,
            responsibleCode: null,
            responsibleOrgCode: '',
            responsibleOrgName: '',
          });
        }
      };

      if (this.formConfig.code === 'view' || this.formConfig.code === 'edit') {
        request.post('/mdm/newproduct/getDetail', { id: this.formConfig.row.id }).then((res) => {
          if (res.success) {
            this.setValue({
              ...res.result,
              attach: res.result.attach || [],
            });
          }
        });
      }
    },

    // 提交
    submit(type) {
      const formData = this.getFormData(); // 获取表单的值

      if (formData) {
        if (type === 1) { // 暂存
          formData.stagingState = 0;
        } else if (type === 2) { // 正式提交
          formData.stagingState = 1;
        }
        // 默认新增
        const url = '/mdm/newproduct/edit';
        // 有id  就是编辑
        if (this.formConfig.code === 'view' || this.formConfig.code === 'edit') {
          formData.id = this.formConfig.row.id;
        }
        request.post(url, formData).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
