import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import ProductList from './product_list.vue';
import CustomerList from './customer_list.vue';
import ProductSeries from './product_series.vue';

formCreate.component('ProductList', ProductList);
formCreate.component('ProductSeries', ProductSeries);
formCreate.component('CustomerList', CustomerList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'product_adjustment_form',
      formParentCode: 'CRM20210913000002692',
      buttonText: {
        submit: '保存',
      },
      buttons: {
        submitAudit: this.formConfig.code !== 'view',
      },
    };
  },

  methods: {
    // 重置规则
    setRule(v) {
      const item = v;
      if (item.field === 'customerName') { // 选择客户
        item.props = {
          ...item.props,
          params: {
            functionCode: 'customer_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: item.title,
        };
      }

      if (item.field === 'productSeriesName') { // 产品系列
        item.props = {
          ...item.props,
          params: {
            functionCode: 'product_series',
          },
          getValue: (field) => this.getFieldValue(field),
          title: item.title,
        };
      }

      if (item.field === 'listedDate') {
        item.props = {
          ...item.props,
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
        };
      }
      if (item.field === 'moveDate') {
        item.props = {
          ...item.props,
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
        };
      }

      if (item.field === 'changeBeforeProductName') { // 选择微调前产品
        item.props = {
          ...item.props,
          params: {
            functionCode: 'product_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: item.title,
        };
      }
      if (item.field === 'changeAfterAlcoholDegree') { // 酒精度
        item.on = {
          change: (e) => {
            this.setValue({
              changeAfterAlcoholDegree: e,
            });
          },
          getLabel: (e) => {
            this.setValue({
              changeAfterAlcoholDegreeName: e,
            });
          },
        };
      }
      if (item.field === 'beforeAttach') {
        item.props = {
          ...item.props,
          limit: 999,
          fileMsg: '只能上传jpg/png文件',
          accept: '.png,.jpg,.jpeg',
        };
      }
      if (item.field === 'afterAttach') {
        item.props = {
          ...item.props,
          limit: 999,
          fileMsg: '只能上传jpg/png文件',
          accept: '.png,.jpg,.jpeg',
        };
      }
      return v;
    },
    // 完成渲染
    formComplete() {
      const listedDate = this.getRule('listedDate'); // 上市时间处理
      listedDate.props = {
        ...listedDate.props,
        pickerOptions: {
          disabledDate: (time) => time.getTime() > Date.now(), // - 8.64e7 如果没有后面的-8.64e7就是不可以选择今天的
        },
      };

      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      const { type, id } = this.formConfig;
      const changeBeforeProductName = this.getRule('changeBeforeProductName'); // 微调前产品
      changeBeforeProductName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            changeBeforeProductName: e[0].materialName,
            changeBeforeProductCode: e[0].materialCode,
            changeBeforAlcoholDegree: e[0].ext5, // 酒精度
            changeBeforeStandard: e[0].specification, // 规格
            changeBeforeProductType: e[0].ext4, // 产品类型
            changeBeforeSmell: e[0].ext2, // 香型
            changeBeforeContent: e[0].capacity, // 净含量
          });
        } else {
          this.setValue({
            productName: null,
            productCode: null,
            productSeries: null,
            calculateUnit: null,
            calculateUnitName: null,
          });
        }
      };

      // 产品系列
      const productSeriesName = this.getRule('productSeriesName');
      productSeriesName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            productSeriesName: e[0].productLevelName,
            productSeries: e[0].productLevelCode,
          });
        } else {
          this.setValue({
            productSeriesName: null,
            productSeries: null,
          });
        }
      };

      // 客户名称
      const customerName = this.getRule('customerName');
      customerName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            customerName: e[0].customerName,
            customerCode: e[0].customerCode,
            legalRepresentative: e[0].legalRepresentative,
            phoneCode: e[0].mdmCustomerContactVos ? e[0].mdmCustomerContactVos[0].contactPhone : '',
            responsibleCode: userInfo.userName,
            responsibleName: userInfo.fullName,
            address: e[0].registeredAddress,
            responsibleOrgCode: userInfo.orgCode,
            responsibleOrgName: userInfo.orgName,
          });
        } else {
          this.setValue({
            customerName: null,
            customerCode: null,
            legalRepresentative: null,
            phoneCode: null,
            address: null,
            responsibleName: null,
            responsibleCode: null,
            responsibleOrgCode: '',
            responsibleOrgName: '',
          });
        }
      };

      if (this.formConfig.code === 'view' || this.formConfig.code === 'edit') {
        request.post('/mdm/productchange/getDetail', { id: this.formConfig.row.id }).then((res) => {
          if (res.success) {
            this.setValue({
              ...res.result,
              attach: res.result.attach || [],
              afterAttach: res.result.afterAttach || [],
              beforeAttach: res.result.beforeAttach || [],
            });
          }
        });
      }
    },

    // 提交
    submit(type) {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        if (type === 1) { // 暂存
          formData.stagingState = '0';
        } else if (type === 2) { // 正式提交
          formData.stagingState = '1';
        }
        // 默认新增
        const url = '/mdm/productchange/edit';
        // 有id  就是编辑
        if (this.formConfig.code === 'view' || this.formConfig.code === 'edit') {
          formData.id = this.formConfig.row.id;
        }
        request.post(url, formData).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
